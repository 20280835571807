@import 'styles/_variables.scss';
@import 'open-color/open-color';

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: $header-height;

  &__title {
    font-size: 60px;
  }
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: $footer-height;
  font-size: 20px;
  
  &__author {
    margin-right: 20px;
  }

  &__github {
    color: black;
  }
}