@import 'styles/_variables';

.board__item {
  width: $border-item-size;
  height: $border-item-size;
  margin: 3px;
  border-radius: 3px;
  box-shadow: 0 2px 0 #bbb;
  cursor: pointer;
  transition: background-color 0.3s ease-out;
}