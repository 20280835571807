@import url(https://fonts.googleapis.com/css?family=Julius+Sans+One&display=swap);


.content-header__select:disabled,.content-header__button:disabled,.content-header__button--pause:disabled,.content-header__button--usage:disabled{background-color:#adb5bd;box-shadow:none}.content-header{display:flex;flex-direction:row;justify-content:center;height:calc(9vh - 20px);margin-bottom:20px;padding:10px 0;box-sizing:border-box;font-size:20px;color:white}.content-header__select{height:100%;width:200px;margin-right:20px;border-radius:none;font-family:inherit;font-size:inherit;color:inherit;border:none;outline:none;cursor:pointer;-webkit-appearance:none;-moz-appearance:none;appearance:none;background:#5c7cfa;background-image:url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");background-repeat:no-repeat;background-position-x:100%;background-position-y:5px;padding-left:10px}.content-header__button,.content-header__button--pause,.content-header__button--usage{display:flex;font-family:inherit;font-weight:bold;font-size:inherit;cursor:pointer;background:#339af0;-moz-appearance:none;appearance:none;-webkit-appearance:none;border:none;padding:0 10px;margin-right:20px;box-shadow:0 3px 3px 0 #74c0fc;color:inherit;-webkit-transition:-webkit-transform 0.2s linear;transition:-webkit-transform 0.2s linear;transition:transform 0.2s linear;transition:transform 0.2s linear, -webkit-transform 0.2s linear;outline:none}.content-header__button--pause{background-color:#20c997}.content-header__button--usage{background-color:#40c057;box-shadow:0 3px 3px 0 #8ce99a}.content-header__button:last-child,.content-header__button--pause:last-child,.content-header__button--usage:last-child{margin-right:0}.content-header__button:hover:enabled,.content-header__button--pause:hover:enabled,.content-header__button--usage:hover:enabled{-webkit-transform:translateY(-10%);transform:translateY(-10%)}

.board{display:flex;flex-direction:column;outline:none;height:calc(100vh - (10vh + 9vh + 10vh));margin:0 auto}.board__row{display:flex;flex-direction:row;justify-content:center}

.board__item{width:calc(calc(calc(100vh - (10vh + 9vh + 10vh)) / 16) - 5px);height:calc(calc(calc(100vh - (10vh + 9vh + 10vh)) / 16) - 5px);margin:3px;border-radius:3px;box-shadow:0 2px 0 #bbb;cursor:pointer;-webkit-transition:background-color 0.3s ease-out;transition:background-color 0.3s ease-out}

.modal-info__content{font-size:20px}.modal-info{position:absolute;top:50%;left:50%;-webkit-transform:translate(-50%, -50%);transform:translate(-50%, -50%);width:400px;height:200px;background-color:white;border-radius:5px;outline:none;box-shadow:0 3px 0 0 rgba(0,0,0,0.4);text-align:center;display:flex;flex-direction:column;justify-content:center;font-weight:bold;border-top:7px solid #4263eb;width:700px;height:350px;padding:20px;align-items:center}.modal-info__title{font-family:inherit;font-size:36px;margin-bottom:20px;color:#4263eb}.modal-info__color{margin-bottom:20px}.modal-info__usage .modal-info__row{margin-bottom:20px}.modal-info__usage .modal-info__square,.modal-info__usage .modal-info__square--initial,.modal-info__usage .modal-info__square--visited,.modal-info__usage .modal-info__square--clicked,.modal-info__usage .modal-info__square--fixed,.modal-info__usage .modal-info__square--shortest{margin-right:10px}.modal-info__usage h2{margin-right:10px;font-size:20px}.modal-info__row{display:flex;align-items:center}.modal-info__content{width:150px;display:flex;flex-direction:row;align-items:center;margin-bottom:20px}.modal-info__square,.modal-info__square--initial,.modal-info__square--visited,.modal-info__square--clicked,.modal-info__square--fixed,.modal-info__square--shortest{display:block;width:24px;height:24px;margin-right:20px}.modal-info__square--initial{background-color:#a1a6a3}.modal-info__square--visited{background-color:#75e1ff}.modal-info__square--clicked{background-color:#fcba03}.modal-info__square--fixed{background-color:#000}.modal-info__square--shortest{background-color:#cf2541}.modal-info__close{position:absolute;right:10px;top:10px;border-radius:50%;width:25px;height:25px;border:none;background-color:#4263eb;color:white;cursor:pointer;-webkit-transition:opacity 0.3s linear;transition:opacity 0.3s linear;opacity:0.5;outline:none}.modal-info__close:hover{opacity:1}

.modal-error__content{font-size:20px}.modal-error{position:absolute;top:50%;left:50%;-webkit-transform:translate(-50%, -50%);transform:translate(-50%, -50%);width:400px;height:200px;background-color:white;border-radius:5px;outline:none;box-shadow:0 3px 0 0 rgba(0,0,0,0.4);text-align:center;display:flex;flex-direction:column;justify-content:center;font-weight:bold;border-top:7px solid #f03e3e}.modal-error__title{font-family:inherit;font-size:36px;margin-bottom:20px;color:#f03e3e}.modal-error__close{position:absolute;right:10px;top:10px;border-radius:50%;width:25px;height:25px;border:none;background-color:#f03e3e;color:white;cursor:pointer;-webkit-transition:opacity 0.3s linear;transition:opacity 0.3s linear;opacity:0.5;outline:none}.modal-error__close:hover{opacity:1}

.header{display:flex;justify-content:center;align-items:center;height:10vh}.header__title{font-size:60px}.footer{display:flex;justify-content:center;align-items:center;height:10vh;font-size:20px}.footer__author{margin-right:20px}.footer__github{color:black}

body,html{height:100%}body{font-family:'Julius Sans One', sans-serif;-ms-user-select:none;user-select:none;-webkit-user-select:none;-moz-user-select:none}

