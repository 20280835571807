@import url('https://fonts.googleapis.com/css?family=Julius+Sans+One&display=swap');

body,html {
  height: 100%;
}

body {
  font-family: 'Julius Sans One', sans-serif;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}