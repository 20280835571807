$visited-color: #75e1ff;
$shortest-color: #cf2541;
$fixed-color: #000000;
$clicked-color: #fcba03;
$initial-color: #a1a6a3;

$header-height: 10vh;
$content-header-height: 9vh;
$footer-height: 10vh;
$board-height: calc(100vh - (#{$header-height} + #{$content-header-height} + #{$footer-height}));

$board-row: 16;
$board-col: 30;

$border-item-size: calc(calc(#{$board-height} / #{$board-row}) - 5px);

:export {
  visitedColor: $visited-color;
  shortestColor: $shortest-color;
  fixedColor: $fixed-color;
  clickedColor: $clicked-color;
  initialColor: $initial-color;
  
  boardRow: $board-row;
  boardCol: $board-col;
}